import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import { thunk } from 'redux-thunk';
//
import { rootPersistConfig, rootReducer } from './rootReducer';

// ----------------------------------------------------------------------

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  devTools: import.meta.NODE_ENV !== 'production',
  // middleware: [thunk]
});

const persistor = persistStore(store);

export { store, persistor };
