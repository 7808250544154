import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosInstance from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  pieData : [],
  interactions : [],
  documents : [],
};

const slice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    // LOADING
    updateLoadingState(state,payload) {
      state.isLoading = payload;
    },

    // START LOADING
    startChatRespLoading(state) {
      state.msgListLoading = true;
    },

    // START LOADING
    startChatListLoading(state) {
      state.isChatListLoading = true;
    },

    getQuestions(state,{payload}) {
      state.pieData = payload;
    },
    getInteractions(state,{payload}) {
      state.interactions = payload;
    },
    getDocuments(state,{payload}) {
      state.documents = payload;
    },

  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getQuestions } =
  slice.actions;



// ----------------------------------------------------------------------




export function fetchQuestions(start_date,end_date) {
  return async (dispatch) => {
      try {
          const response = await axiosInstance.get('/home/personal/question_types', {
            params: { start_date,end_date }
          });
          dispatch(slice.actions.getQuestions(response.data)); // Ensure this only contains the titles
      } catch (error) {
          console.error(error);
        //   dispatch(slice.actions.hasError(error));
      }
  };
}


export function fetchInteractions(start_date,end_date) {
  return async (dispatch) => {
      try {
          const response = await axiosInstance.get('/home/personal/interactions_daily', {
            params: { start_date,end_date }
          });
          dispatch(slice.actions.getInteractions(response.data)); // Ensure this only contains the titles
      } catch (error) {
          console.error(error);
        //   dispatch(slice.actions.hasError(error));
      }
  };
}

export function fetchDocuments(start_date,end_date) {
  return async (dispatch) => {
      try {
          const response = await axiosInstance.get('/home/personal/document-stats', {
            params: { start_date,end_date }
          });
          dispatch(slice.actions.getDocuments(response.data)); // Ensure this only contains the titles
      } catch (error) {
          console.error(error);
        //   dispatch(slice.actions.hasError(error));
      }
  };
}


// ----------------------------------------------------------------------
