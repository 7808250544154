import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosInstance from '../../utils/axios';

const dummyJson = [
  {
    title: "What is the guidance on waste water management?",
    actionType: "search",
    extended: "Could you provide the latest comprehensive guidance and regulations on waste water management, including best practices, legal requirements, and emerging trends in the UK?"
  },
  {
    title: "How to effectively conserve water?",
    actionType: "search",
    extended: "What are the most effective strategies and methods for water conservation, specifically focusing on techniques suitable for applications in estates and facilities in the healthcare sector in the UK?"
  },
  {
    title: "What is the net zero carbon target of the NHS?",
    actionType: "search",
    extended: "What are the specific goals, timelines, and strategies the NHS has set to achieve net zero carbon emissions? Please include details on key initiatives and milestones."
  },
  {
    title: "What steps do I need to follow to replace LEDs?",
    actionType: "search",
    extended: "Can you outline the detailed steps and considerations involved in replacing old lighting systems with LEDs, including selection criteria, installation procedures, and energy-saving benefits?"
  },
  {
    title: "Concise Overview of Sustainable Healthcare Practices",
    actionType: "summarise",
    extended: "Provide a concise overview of sustainable healthcare practices, highlighting key initiatives, benefits, and examples of successful implementations in healthcare facilities."
  },
  {
    title: "Summarise the Healthcare Waste Management Guidelines",
    actionType: "summarise",
    extended: "Summarise the key points of the latest healthcare waste management guidelines, focusing on safe disposal methods, regulatory compliance, and environmental impact."
  },
  {
    title: "Tell Me the Key Points on Water Conservation Techniques",
    actionType: "summarise",
    extended: "Summarise the essential water conservation techniques, including both traditional and innovative methods, and their applicability in various settings such as homes, businesses, and public spaces."
  },
  {
    title: "Overview of NHS Net Zero Carbon Goals",
    actionType: "summarise",
    extended: "Provide an overview of the NHS's net zero carbon goals, including key targets, strategies, and anticipated challenges, as well as progress made to date."
  },
  {
    title: "Writing Guidelines for Hospital Waste Water Management",
    actionType: "write",
    extended: "Create a draft of comprehensive guidelines for hospital waste water management, covering legal requirements, best practices, risk mitigation strategies, and examples of successful implementations."
  },
  {
    title: "Initial Draft on Water Conservation Techniques",
    actionType: "write",
    extended: "Develop an initial draft detailing various water conservation techniques, including practical tips, case studies, and the potential impact on water usage and cost savings."
  },
  {
    title: "Preliminary Draft on Net Zero Targets for Healthcare",
    actionType: "write",
    extended: "Prepare a preliminary draft outlining net zero carbon targets for the healthcare sector, including specific goals, timelines, implementation strategies, and measurement criteria."
  },
  {
    title: "Write Outline for LED Replacement Process",
    actionType: "write",
    extended: "Write an outline for the process of replacing traditional lighting with LED lights, focusing on planning, procurement, installation, and expected energy and cost savings."
  },
  {
    title: "Revised Guidelines for Waste Water Management",
    actionType: "enhance",
    extended: "Provide a revised version of the existing guidelines for waste water management, incorporating the latest regulatory updates, technological advancements, and best practices."
  },
  {
    title: "Updated Techniques for Water Conservation",
    actionType: "enhance",
    extended: "Rewrite the current document on water conservation techniques to reflect updated methods, recent research findings, and new technologies that enhance water-saving efforts."
  },
  {
    title: "Enhanced Net Zero Carbon Targets for NHS",
    actionType: "enhance",
    extended: "Rewrite the existing targets for NHS net zero carbon emissions, enhancing the document with more detailed strategies, updated timelines, and new initiatives that have been introduced."
  },
  {
    title: "Improved Steps for Replacing LED Lights",
    actionType: "enhance",
    extended: "Rewrite the guidelines on replacing LED lights to improve clarity, include recent advancements in LED technology, and provide more detailed instructions on the installation process."
  },
];

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  msgListLoading: false,
  msgSearchLoading: false,
  isChatListLoading: false,
  error: false,
  chatList: [],
  singleChat: null,
  searchValue:"",
  sortBy: null,
  searchValue:"",
  chatFilters: [],
  CATEGORY_OPTION: [],
  DOMAIN_OPTION: [],
  SUBDOMAIN_OPTION: [],
  DOCUMENT_OPTION: [],
  conversation: [],
  filterMapping: {},
  wikiData: {},
  CHART_CARD_JSON: dummyJson,
  relatedQuestions: [],
  currentAction: 'search',
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setCurrentAction(state, action) {
      state.currentAction = action.payload;
    },

    // START LOADING
    startChatRespLoading(state) {
      state.msgListLoading = true;
    },

    // START LOADING
    startChatListLoading(state) {
      state.isChatListLoading = true;
    },

    // START LOADING
    startChatSearchLoading(state) {
      state.msgSearchLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Chat List
    getChatsSuccess(state, action) {
      state.isChatListLoading = false;
      state.chatList = action.payload;
    },

    // GET Single Chat
    getSingleChatSuccess(state, action) {
      state.msgSearchLoading = false;
      state.CHART_CARD_JSON = [{}];
      state.singleChat = action.payload?.res;
      const newArr = [...state.conversation];

      const obj1 = {
        content: action.payload?.qst,
        role: 'user'
      };
      const sourcesArr = action.payload?.res?.titles?.map((item, index) => ({
        title: item,
        url: action.payload?.res?.urls[index],
        page: action.payload?.res?.pages[index],
        date: action.payload?.res?.publication_dates[index]
      }));

      const obj2 = {
        content: action.payload?.res?.answer,
        follow_up_questions: action.payload?.res?.follow_up_questions,
        titles: action.payload?.res?.titles,
        urls: action.payload?.res?.urls,
        pages: action.payload?.res?.pages,
        publication_dates: action.payload?.res?.publication_dates,
        sources: sourcesArr,
        role: 'assistant'
      };
      newArr.push(obj1, obj2);
      state.conversation = newArr;
    },

    // GET Single Chat
    getFeedBack(state, action) {
      state.isLoading = false;
      // state.conversation = action.payload;
    },

    // GET Single Chat
    getSpecificChatSuccess(state, action) {
      state.msgListLoading = false;
      state.CHART_CARD_JSON = [{}];
      state.conversation = action.payload;
    },

    // DELETE Chat
    deleteChatSuccess(state, action) {
      state.isChatListLoading = false;
      const newChatList = { ...state.chatList };
      Object.keys(newChatList).forEach((key) => {
        newChatList[key] = newChatList[key].filter(chat => chat.id !== action.payload.id);
      });
      state.chatList = newChatList;
    },    

    // GET Single Chat
    newChatSuccess(state, action) {
      state.msgListLoading = false;
      state.singleChat = action.payload;
    },

    // get chat filters
    chatFiltersSuccess: (state, { payload }) => {
      const value1 = payload?.data['Document Category'];
      const value2 = payload?.data['Document Sub-Domain'];
      const value3 = payload?.data['Sub-Domains'];
      const value4 = payload?.data.Reference;
      state.CATEGORY_OPTION = value1;
      state.DOMAIN_OPTION = value2;
      state.SUBDOMAIN_OPTION = value3;
      state.DOCUMENT_OPTION = value4;
      // state.chatFilters = payload;
    },

    // GET Single Chat
    handleClearChat: (state, { payload }) => {
      state.conversation = [];
      state.CHART_CARD_JSON = dummyJson;
    },

    handleClearFilter: (state, { payload }) => {
        state.chatFilters = {category: ['Guidance'], ...payload };
      
    },

    // GET chart Cards
    handleChatCards: (state, { payload }) => {
      state.CHART_CARD_JSON = payload;
    },

    // chat filters
    handleChatFilters: (state, { payload }) => {
      state.chatFilters = payload;
    },

    // chat selected
    handleSelectedChat: (state, { payload }) => {
      state.selectedChatId = payload;
    },

    // GET Related Questions
    getRelatedQuestionsSuccess(state, action) {
      state.isLoading = false;
      state.relatedQuestions = action.payload;
    },

    setFilterMappingSuccess: (state, action) => {
      const documentsData = action.payload; // The raw data from your API

      // Initialize structures for categories, domains, subdomains, and documents
      const categories = new Set();
      const domains = {};
      const subDomainsMapping = {};
      const documentsMapping = {}; // Updated to include direct domain-document mapping
      const domainDocumentsMapping = {}; // New mapping for domains to documents
      const categoryDocumentsMapping = {}; // New mapping for categories to documents
      const subDomainToCategoryMapping = {};
      const domainsToCategories = {};
      const subDomainsToDomains = {};
      const reverseMapping = {
        // Reverse mapping for documents to all parent filters
        documentsToSubDomains: {},
        subDomainsToDomains: {},
        domainsToCategories: {},
        documentsToDomains: {},
        documentsToCategories: {},
      };

      Object.entries(documentsData).forEach(([docName, docDetails]) => {
        const {
          'Document Category': category,
          'Document Sub-Domain': domain,
          'Sub-Domains': subDomain,
          Reference: document
        } = docDetails;

        // Populate categories
        categories.add(category);

        // Populate domains
        if (!domains[category]) {
          domains[category] = new Set();
        }
        domains[category].add(domain);

        // Map domains to categories
        if (!domainsToCategories[domain]) {
          domainsToCategories[domain] = new Set();
        }
        domainsToCategories[domain].add(category);

        // Map subdomains to domains
        if (!subDomainsToDomains[subDomain]) {
          subDomainsToDomains[subDomain] = new Set();
        }
        subDomainsToDomains[subDomain].add(domain);

        // Populate subdomain mappings
        if (!subDomainsMapping[domain]) {
          subDomainsMapping[domain] = new Set();
        }
        subDomainsMapping[domain].add(subDomain);

        // Populate document mappings (assumes a subdomain can have multiple documents)
        if (!documentsMapping[subDomain]) {
          documentsMapping[subDomain] = new Set();
        }
        documentsMapping[subDomain].add(document); // Use the document reference as the unique identifier

        // New logic to map documents directly to domains
        if (!domainDocumentsMapping[domain]) {
          domainDocumentsMapping[domain] = new Set();
        }
        domainDocumentsMapping[domain].add(document);

        // New logic: Map documents directly to categories
        if (!categoryDocumentsMapping[category]) {
          categoryDocumentsMapping[category] = new Set();
        }
        categoryDocumentsMapping[category].add(document);

        // New logic: Map categories directly based on subdomain
        if (!subDomainToCategoryMapping[subDomain]) {
          subDomainToCategoryMapping[subDomain] = new Set();
        }
        subDomainToCategoryMapping[subDomain].add(category);

        // Populate reverse mappings
        reverseMapping.subDomainsToDomains[subDomain] = domain;
        reverseMapping.domainsToCategories[domain] = category;
        reverseMapping.documentsToSubDomains[document] = subDomain;
        reverseMapping.documentsToDomains[document] = domain;
        reverseMapping.documentsToCategories[document] = category;
      });

      // Convert Sets to Arrays for easier consumption and create final mappings
      const finalCategories = Array.from(categories);
      const finalDomains = Object.fromEntries(Object.entries(domains).map(([key, value]) => [key, Array.from(value)]));
      const finalSubDomainsMapping = Object.fromEntries(
        Object.entries(subDomainsMapping).map(([key, value]) => [key, Array.from(value)])
      );
      const finalDocumentsMapping = Object.fromEntries(
        Object.entries(documentsMapping).map(([key, value]) => [key, Array.from(value)])
      );
      const finalDomainDocumentsMapping = Object.fromEntries(
        Object.entries(domainDocumentsMapping).map(([key, value]) => [key, Array.from(value)])
      );
      const finalCategoryDocumentsMapping = Object.fromEntries(
        Object.entries(categoryDocumentsMapping).map(([key, value]) => [key, Array.from(value)])
      );
      const finalSubdomainCategoryMapping = Object.fromEntries(
        Object.entries(subDomainToCategoryMapping).map(([key, value]) => [key, Array.from(value)])
      );
      const finalSubdomainDomainMapping = Object.fromEntries(
        Object.entries(subDomainsToDomains).map(([key, value]) => [key, Array.from(value)])
      );
      const finalDomainCategoryMapping = Object.fromEntries(
        Object.entries(domainsToCategories).map(([key, value]) => [key, Array.from(value)])
      );

      // Store mappings in state
      state.filterMapping = {
        categories: finalCategories,
        domains: finalDomains,
        subDomainsMapping: finalSubDomainsMapping,
        documentsMapping: finalDocumentsMapping,
        domainDocumentsMapping: finalDomainDocumentsMapping, // Include the new domain-documents mapping
        categoryDocumentsMapping: finalCategoryDocumentsMapping, // Include the new category-documents mapping
        subDomainToCategoryMapping: finalSubdomainCategoryMapping,
        domainToCategoryMapping: finalDomainCategoryMapping,
        subDomainToDomainMapping: finalSubdomainDomainMapping,
        reverseMapping // Store the reverse mapping in the state
      };
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { handleClearChat, handleClearFilter, handleChatFilters, handleChatCards, handleSelectedChat, getRelatedQuestionsSuccess, startLoading, deleteChatSuccess, setCurrentAction } =
  slice.actions;

// ----------------------------------------------------------------------

export function getChats() {
  return async (dispatch) => {
    dispatch(slice.actions.startChatListLoading());
    try {
      const response = await axiosInstance.get(`chat/chat_history`);
      dispatch(slice.actions.getChatsSuccess(response.data));
    } catch (error) {
      dispatch(slice?.actions?.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFilterMapping() {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(`chat/filter_data`);
      dispatch(slice.actions.setFilterMappingSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSingleChat(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startChatRespLoading());
    try {
      const response = await axiosInstance.get('chat/specific_chat', {
        params: { selected_session: name }
      });
      dispatch(slice.actions.getSpecificChatSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice?.actions?.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function deleteChat(id) {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get('chat/delete_chat', {
        params: { selected_session: id }
      });
      dispatch(slice.actions.deleteChatSuccess({ id }));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function newChat() {
  return async (dispatch) => {
    dispatch(slice.actions.startChatRespLoading());
    try {
      const response = await axiosInstance.get('chat/new_chat');
      dispatch(slice.actions.newChatSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice?.actions?.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getChatResponse(body) {
  return async (dispatch) => {
    dispatch(slice.actions.startChatSearchLoading());
    const fCategory = body?.category_filter?.filter((i) => i !== 'Source Category');
    const fDocument = body?.document_filter?.filter((i) => i !== 'Document');
    const fDomain = body?.domain_filter?.filter((i) => i !== 'Domain');
    const fReference = body?.reference_filter?.filter((i) => i !== 'Sub Domain');
    const newBody = {
      ...body,
      category_filter: fCategory,
      document_filter: fDocument,
      domain_filter: fDomain,
      reference_filter: fReference
    };
    try {
      const response = await axiosInstance.post(`chat/chat_response`, newBody);
      dispatch(slice.actions.getSingleChatSuccess({ res: response.data, qst: body?.data?.detail[0]?.msg }));
    } catch (error) {
      console.error(error);
      dispatch(slice?.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function sendFeedBack(body) {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(`chat/post-feedback`, body);
      dispatch(slice.actions.getFeedBack(response));
    } catch (error) {
      console.error(error);
      dispatch(slice?.actions?.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function GetChatFilters(body) {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(`chat/filter`);
      dispatch(slice.actions.chatFiltersSuccess(response));
    } catch (error) {
      console.error(error);
      dispatch(slice?.actions?.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// New action to fetch related questions
export function fetchRelatedQuestions(question) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosInstance.post('chat/similar-question', { question });
      dispatch(getRelatedQuestionsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}