const env = import.meta.env;
export const firebaseConfig = {
  apiKey: env.VITE_FIREBASE_API_KEY,
  authDomain: env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: env.VITE_FIREBASE_DATABASE_URL,
  projectId: env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: env.VITE_FIREBASE_APPID,
  measurementId: env.VITE_FIREBASE_MEASUREMENT_ID
};

export const cognitoConfig = {
  userPoolId: env.VITE_AWS_COGNITO_USER_POOL_ID,
  clientId: env.VITE_AWS_COGNITO_CLIENT_ID
};

export const auth0Config = {
  clientId: env.VITE_AUTH0_CLIENT_ID,
  domain: env.VITE_AUTH0_DOMAIN
};

export const mapConfig = env.VITE_MAP_MAPBOX;

export const googleAnalyticsConfig = env.VITE_GA_MEASUREMENT_ID;