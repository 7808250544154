import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// hooks
import useAuth from './hooks/useAuth';
import { setupNotificationPolling } from './redux/slices/notifications';

// components
// import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
// import LoadingScreen from './components/LoadingScreen';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import CookieConsent from "react-cookie-consent";
import CookieConsentDialog from './CookieConsentDialog';
const env = import.meta.env;
const vite_pendo_id = (env.VITE_PENDO_PROJECT_ID);
const vite_clarity_id = (env.VITE_CLARITY_PROJECT_ID);

const loadClarityScript = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.innerHTML = `
    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${vite_clarity_id}");
  `;
  document.head.appendChild(script);
};

const loadPendoScript = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { firstname, email, user_id, organisation, surname, job_title } = user;
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.innerHTML = `
    (function (apiKey) {
      (function (p, e, n, d, o) {
        var v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = o._q || [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w) (function (m) {
          o[m] = o[m] || function () {
            o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
          };
        })(v[w]);
        y = e.createElement(n);
        y.async = !0;
        y.src = 'https://cdn.eu.pendo.io/agent/static/' + apiKey + '/pendo.js';
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');

      pendo.initialize({
        visitor: {
          id: '${user_id}', // Required
          email: '${email}', // Recommended if available
          name: '${firstname} ${surname}', // Optional
          jobTitle: '${job_title}', // Optional
        },
        account: {
          id: '${organisation || 'NoOrganisation'}',
        }
      });
    })('${vite_pendo_id}');
  `;

  document.head.appendChild(script);
};


const App = () => {
  const { isInitialized } = useAuth();
  const dispatch = useDispatch();
  const [cookieConsentOpen, setCookieConsentOpen] = useState(true);

  // Set up notification polling when app initializes
  useEffect(() => {
    if (isInitialized) {
      const cleanup = dispatch(setupNotificationPolling());
      return () => {
        if (cleanup) cleanup();
      };
    }
  }, [dispatch, isInitialized]);

  useEffect(() => {
    if (document.cookie.includes('clarity_consent=true')) {
      setCookieConsentOpen(false);
      loadClarityScript();
      loadPendoScript();
    }
  }, []);

   const handleCookieConsentAccept = () => {
     const expirationDate = new Date();
     expirationDate.setDate(expirationDate.getDate() + 150);
     // Set the cookie to indicate that the user has accepted the cookie consent
     document.cookie = `clarity_consent=true; expires=${expirationDate.toUTCString()};`;
     setCookieConsentOpen(false);
     loadClarityScript();
   };

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <ScrollToTop />
              <GoogleAnalytics />
              <CookieConsentDialog
                open={cookieConsentOpen}
                onClose={() => setCookieConsentOpen(false)}
                onAccept={handleCookieConsentAccept}
              />
              <Router />
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
};

export default App;