import { createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  notifications: [],
  unreadCount: 0
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
      state.unreadCount = action.payload.filter(notification => notification.status === 'unread').length;
    },
    markAsReadSuccess(state, action) {
      const notificationIds = action.payload;
      state.notifications = state.notifications.map(notification => 
        notificationIds.includes(notification.id) 
          ? { ...notification, status: 'read' }
          : notification
      );
      state.unreadCount = state.notifications.filter(n => n.status === 'unread').length;
    }
  }
});

export default slice.reducer;

export function getNotifications() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.get('/notifications/user');
      dispatch(slice.actions.getNotificationsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
}

export function markNotificationsAsRead(notificationIds) {
  return async (dispatch) => {
    try {
      await axiosInstance.post('/notifications/mark-as-read', { notification_ids: notificationIds });
      dispatch(slice.actions.markAsReadSuccess(notificationIds));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
}

// New action for periodic fetching
export function setupNotificationPolling() {
  return async (dispatch) => {
    // Initial fetch
    dispatch(getNotifications());

    // Set up periodic fetching
    const pollInterval = setInterval(() => {
      dispatch(getNotifications());
    }, 90000); // Poll every 90 seconds

    // Return cleanup function
    return () => clearInterval(pollInterval);
  };
}
